<template>
  <c-modal
    ref="color-selection-popup-modal"
    :class="{ 'is-visible': isVisible }"
    @close="handleClose">
    <c-modal-header>
      <h3 class="my-0">
        Vælg farve
        <template v-if="sideType.length"> til {{ getSideTypeName(sideType) }}</template>
        <template v-else> på dør nr {{ doorNumber + 1 }}<template v-if="doorPartLabel.length">, {{ doorPartLabel.toLowerCase() }} stykke</template>
        </template>

      </h3>
    </c-modal-header>

    <c-modal-body>
      <validation-observer
        ref="filling-selection-form"
        tag="form">
        <validation-provider
          rules="required|min_value:1"
          name="door-filling-options"
          class="door-filling-options relative"
          v-slot="{ errors }">
          <c-form-radio
            v-for="(filling, indexFilling) in (sideType.length ? availableFillings[sideType] : availableFillings.door)"
            :key="indexFilling"
            class="door-filling-wrapper mr-0 mb-0"
            :withImage="true"
            :value="filling.id"
            v-model="selectedFilling">
            <div
              class="door-filling"
              :style="{ 'backgroundImage': getFillingStyle(filling.thumb) }"></div>
            <span class="c-form-radio-label door-filling-name">{{ filling.name }}</span>
          </c-form-radio>
          <div class="invalid-feedback absolute">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </validation-observer>
    </c-modal-body>
    <c-modal-footer>
      <div class="flex align-items-center">
        <c-button
          class="ml-0 mr-auto"
          theme="warning"
          @click.prevent="handleClose">
          Anullere
        </c-button>
        <c-button
          theme="primary"
          @click.prevent="validateForm">
          Vælg
        </c-button>
      </div>
    </c-modal-footer>
  </c-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'color-selection-popup',
  props: {
    availableFillings: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedFillingObj () {
      if (!this.selectedFilling) {
        return null;
      }

      let fillingsForSurface = [];
      if (this.sideType) {
        fillingsForSurface = this.availableFillings[this.sideType];
      } else {
        fillingsForSurface = this.availableFillings.door;
      }

      return fillingsForSurface.find(item => item.id === this.selectedFilling);
    }
  },
  data() {
    return {
      data: {
      },
      doorNumber: 0,
      doorPart: '',
      doorPartLabel: '',
      selectedFilling: 0,
      sideType: '',
      isVisible: false
    }
  },
  mounted() {
    this.$bus.$on('color-selection-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      this.isVisible = false;
      document.body.classList.remove('no-scroll');
    },
    openPopup(doorNumber = null, doorPart = null, doorPartLabel = '', selectedFilling = 0, sideType = '') {
      this.doorNumber = doorNumber;
      this.doorPart = doorPart;
      this.doorPartLabel = doorPartLabel;
      this.selectedFilling = selectedFilling;
      this.sideType = sideType;

      setTimeout(() => {
        this.isVisible = true;
        document.body.classList.add('no-scroll');
        if (this.$refs['filling-selection-form']) {
          this.$refs['filling-selection-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['filling-selection-form'], this.saveSelection);
    },
    saveSelection () {
      this.handleClose();
      this.$bus.$emit('update-door-filling', this.doorNumber, this.doorPart, this.selectedFilling, this.sideType, this.selectedFillingObj.is_mirror);
    },
    getFillingStyle (filling) {
      return 'url(' + filling + ')';
    },
    getSideTypeName (sideType) {
      if (sideType === 'impact') {
        return 'anslag';
      }

      if (sideType === 'gable') {
        return 'gavl';
      }

      return '';
    }
  },
  beforeDestroy () {
    this.$bus.$off('color-selection-popup-show', this.openPopup);
  }
}
</script>
