<template>
  <div>
    <transition name="fade">
      <component
        :is="layout"
        class="page-wrapper">
        <header class="site-header">
          <div class="wrapper">
            <div class="flex flex-align-items-center">
              <img
                class="logo"
                :src="require('@/assets/images/pandora-kitchen-logo.jpg')"
                alt="Pandora Kitchen Logo" />
              <div class="flex flex-align-items-center ml-auto">
                <a
                  class="lnk lnk-light"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://pandorakitchen.dk/wp-content/uploads/2022/07/PandoraKitchen-Skydedoere.pdf">
                  Hent samlevejledning
                </a>

                <p
                  v-if="isPriceVisible && !isDiscount"
                  class="text-light ml-3">
                  Din pris: {{ wardrobePrice.total_price }} kr
                </p>
                <p
                  v-if="isPriceVisible && isDiscount"
                  class="text-light ml-3">
                  Din pris: <span class="price-before-discount">{{ wardrobePrice.total_price_before_discount }} kr</span> <span class="price-discounted">{{ wardrobePrice.total_price }} kr</span>
                </p>
              </div>
            </div>
          </div>
        </header>
        <div class="site-content">
          <div class="alert alert-info">
            <p class="wrapper">Vi beregner 10mm luft fra husvæggene.</p>
          </div>
          <template v-if="loadError">
            <div class="wrapper my-auto">
              <div class="alert alert-danger">
                Fejl ved indlæsning af data.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Opdatere siden
                </a>
                for at prøve igen.
              </div>
            </div>
          </template>
          <template v-else-if="allDataLoaded">
            <router-view :key="$route.fullPath" />
          </template>
          <template v-else>
            <div class="loader">
              <span>Indlæser data&hellip;</span>
            </div>
          </template>
        </div>
      </component>
    </transition>
    <cookie-banner ref="cookie-banner" />
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import { autoSaveProject } from '@/utils/AutoSave.js';
import CookieBanner from '@/components/popups/CookieBanner.vue';

export default {
  components: {
    'cookie-banner': CookieBanner
  },
  data () {
    return {
      initialDataLoaded: false,
      isFetched: false,
      loadError: false,
      wardrobePrice: {},
      isAutoSaveActivated: false
    }
  },
  computed: {
    allDataLoaded () {
      return this.initialDataLoaded && this.isFetched;
    },
    layout () {
      if (!this.$route.meta) {
        return 'default-layout';
      }

      return `${this.$route.meta.layout || 'default'}-layout`;
    },
    isPriceVisible () {
      if (!this.wardrobePrice || !this.wardrobePrice.total_price) {
        return false
      }

      if (this.$route.path === '/basic-information' || this.$route.path === '/start-screen') {
        return false;
      }

      return true;
    },
    isDiscount () {
      if (!this.wardrobePrice || !this.wardrobePrice.total_price) {
        return false
      }

      if (this.$route.path === '/basic-information' || this.$route.path === '/start-screen') {
        return false;
      }

      if (!this.wardrobePrice.total_price_before_discount || this.wardrobePrice.total_price_before_discount === this.wardrobePrice.total_price) {
        return false;
      }

      return true;
    }
  },
  watch: {
    '$store.state.wardrobe.price': function(newVal) {
      this.wardrobePrice = newVal;
    },
    '$route.fullPath': function(newVal, oldVal) {
      if (oldVal.endsWith('start-screen')) {
        return;
      }
      if (this.isAutoSaveActivated) {
        let stateTosave = {
          wardrobe: this.$store.getters['getWardrobe'],
          stepsCompletion: this.$store.getters['getStepCompleted']
        }
        autoSaveProject(stateTosave);
      }
    }
  },
  mounted () {
    FormUtils.loadData(this, {
      method: 'get',
      endpoint: '/api/init',
      loadedKey: 'initialDataLoaded',
      errorKey: 'loadError',
      noPagination: true,
      successAction: (response) => {
        this.$store.commit('setDataDoorDivisions', response.door_divisions);
        this.$store.commit('setDataDoorFillings', response.door_fillings);
        this.$store.commit('setDataGableFillings', response.gable_fillings);
        this.$store.commit('setDataGableOptions', response.gable_options);
        this.$store.commit('setDataImpactFillings', response.impact_fillings);
        this.$store.commit('setDataInventory', response.inventory);
        this.$store.commit('setDataInventoryCategories', response.inventory_categories);
        this.$store.commit('setDataWardrobePositions', response.wardrobe_positions);

        this.isFetched = true;
      }
    });

    this.wardrobePrice = this.$store.getters['getWardrobePrice'];

    if (this.$route.path !== '/start-screen') {
      this.$router.push('/start-screen');
    }

    Vue.nextTick(() => {
      this.isAutoSaveActivated = true;
    })
  }
};
</script>
